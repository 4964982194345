import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import FlipMove from "react-flip-move"
import Layout from "../components/layout"
import { Arrow } from "../components/icons"
import { mq, site } from "../styles/variables"

const Top = styled.div`
  margin-bottom: 20px;

  @media ${mq.gte("l")} {
    margin-bottom: 70px;
  }
`

const Heading = styled.h1`
  margin: 0 0 28px;

  @media ${mq.gte("m")} {
    margin-bottom: 42px;
  }
`

const FilterButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 16px;

  > div {
    width: 50%;
  }

  @media ${mq.gte("s")} {
    > div {
      width: auto;
    }
  }

  @media ${mq.gte("m")} {
    justify-content: flex-start;

    > div:not(:first-child) {
      margin-left: 60px;
    }
  }

  @media ${mq.gte("l")} {
    font-size: 20px;

    > div:not(:first-child) {
      margin-left: 75px;
    }
  }
`

const Button = styled.button`
  appearance: none;
  margin: 0 0 20px;
  padding: 0;
  background: transparent;
  border: 0;
  line-height: 1.6;
  color: black;

  &.current {
    box-shadow: inset 0 -2px rgba(0, 0, 0, 1);
  }
`

const Investments = styled.div`
  > div {
    margin: 0 -${site.gutter.s}px 120px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;

    @media ${mq.gte("m")} {
      margin-right: 0;
      margin-left: 0;

      width: calc(100% + ${site.gutter.s}px);
    }

    @media ${mq.gte("l")} {
      margin-bottom: 200px;

      width: calc(100% + ${site.gutter.s}px);
    }
  }
`

const Status = styled.div`
  font-size: 12px;
  color: #70a37b;
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms;
  margin: 0 0 10px;

  @media ${mq.gte("m")} {
    font-size: 14px;
  }
`

const AquiredBy = styled.div`
  font-size: 12px;
  width: 100%;
  padding: 0 ${site.gutter.s}px;
  position: absolute;
  left: 0%;
  bottom: 30px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms;

  @media ${mq.gte("m")} {
    font-size: 16px;
  }

  @media ${mq.gte("l")} {
    bottom: 60px;
  }
`

const ArrowWrapper = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  right: 12px;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translate(-10px, 10px);
  transition: all 200ms 50ms cubic-bezier(0.4, 0, 0.2, 1);
`

const Investment = styled.div`
  width: 50%;
  padding: 22px 0 60px;
  margin: -20px 0;
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: -1;
    transform: scale(1, 0);
    transform-origin: 50% 0;
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  img {
    filter: grayscale(1);
    transition: filter 200ms;
  }

  &:not(.is-open)::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &.is-open {
    z-index: 10;

    &::after {
      transform: scale(1, 0.99);
    }

    img {
      filter: grayscale(0);
    }

    ${Status},
    ${AquiredBy} {
      opacity: 1;
    }

    ${ArrowWrapper} {
      opacity: 1;
      transform: translate(0, 0);
      transition-delay: 0ms;
    }
  }

  @media ${mq.gte("m")} {
    width: calc(50% - ${site.gutter.s}px);
    padding: 34px 0 75px;
    margin: -20px ${site.gutter.s}px -20px 0;
  }

  @media ${mq.gte("l")} {
    width: calc(${100 / 3}% - ${site.gutter.s}px);
    padding: 34px 0 90px;
  }
`

const Logo = styled.div`
  width: 65%;
  display: block;
  margin: 0 auto;

  > div {
    padding-top: ${(100 / 240) * 100}%;
    position: relative;
    display: block;

    > img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;

      &.slim {
        height: 50%;
        top: 25%;
      }
    }
  }
`

const StyledArrow = styled(Arrow)`
  width: 16px;
  height: 14px;
  transform: rotate(-45deg);
`

const Portfolio = ({
  location,
  data: {
    sanityInvestments: { description, title, investments },
  },
}) => {
  const [currentFilter, setCurrentFilter] = useState("all")
  const [open, setOpen] = useState(null)

  const filteredInvestments = investments
    .filter((investment) =>
      currentFilter === "all" ? true : currentFilter === investment.status
    )
    .reverse()

  return (
    <Layout
      color="#DBE3DB"
      location={location}
      description={description}
      title={title}
      onClick={() => setOpen(null)}
    >
      <Top>
        <Heading className="reveal">{title}</Heading>

        <FilterButtons className="reveal">
          <div>
            <Button
              className={currentFilter === "all" ? "current" : ""}
              onClick={() => setCurrentFilter("all")}
            >
              All companies
            </Button>
          </div>
          <div>
            <Button
              className={currentFilter === "active" ? "current" : ""}
              onClick={() => setCurrentFilter("active")}
            >
              Active
            </Button>
          </div>
          <div>
            <Button
              className={currentFilter === "exited" ? "current" : ""}
              onClick={() => setCurrentFilter("exited")}
            >
              Exited
            </Button>
          </div>
          {investments.filter(({ status }) => status === "partnerInvestment")
            .length > 0 && (
            <div>
              <Button
                className={
                  currentFilter === "partnerInvestment" ? "current" : ""
                }
                onClick={() => setCurrentFilter("partnerInvestment")}
              >
                Partner investment
              </Button>
            </div>
          )}
        </FilterButtons>
      </Top>

      <Investments className="reveal">
        <FlipMove>
          {filteredInvestments.map((investment) => {
            const { _key, status, link, image, company, aquiredByCompany } =
              investment

            return (
              <Investment
                as={link ? "a" : "div"}
                href={link}
                rel={link ? "noopener noreferrer" : ""}
                target={link ? "_blank" : ""}
                key={_key}
                onClick={(e) => {
                  e.stopPropagation()

                  if (open !== _key) {
                    e.preventDefault()
                  }

                  setOpen(_key)
                }}
                className={open === _key ? "is-open" : ""}
              >
                <Status
                  dangerouslySetInnerHTML={{
                    __html:
                      status === "exited"
                        ? "Exited"
                        : status === "partnerInvestment"
                        ? "Partner investment"
                        : "&nbsp;",
                  }}
                ></Status>
                <Logo>
                  <div>
                    <img
                      src={
                        image.asset.extension === "svg"
                          ? image.asset.url
                          : image.asset.fluid.src
                      }
                      alt={company}
                      className={
                        image.asset.fluid.aspectRatio > 2 ? "slim" : "full"
                      }
                    />
                  </div>
                </Logo>
                {aquiredByCompany && (
                  <AquiredBy>Acquired by {aquiredByCompany}</AquiredBy>
                )}
                <ArrowWrapper>
                  <StyledArrow />
                </ArrowWrapper>
              </Investment>
            )
          })}
        </FlipMove>
      </Investments>
    </Layout>
  )
}

export default Portfolio

export const query = graphql`
  query PortfolioPageQuery {
    sanityInvestments {
      description
      title
      investments {
        _key
        company
        link
        image {
          asset {
            extension
            url
            fluid(maxWidth: 630) {
              src
              aspectRatio
            }
          }
        }
        status
        aquiredByCompany
      }
    }
  }
`
